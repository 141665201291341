<template>
  <div class="es_reserve-service-container bg_gray">
    <div class="container margin_30_40">
      <div class="row mt-4">
        <div class="col-lg-6 col-md-6 mb-4">
          <div class="box_order">
            <div class="d-flex flex-column align-items-center main">
              <div class="title d-flex flex-column align-items-center">
                <figure>
                  <img
                    src="@/assets/svg/succes.svg"
                    alt=""
                    width="75px"
                    class="lazy"
                  >
                </figure>
                <h3 class="px-10 text-center">{{ $t('reservation_sent_successfully') }}</h3>
              </div>
              <p class="my-6 px-8 text-center">{{ $t('reservation_sent_successfully_text') }} </p>
              <div class="d-flex justify-content-center">
                <button
                  class="btn btn-sm btn-pink btn-pill mt-4 px-6"
                  @click.prevent="$router.push('/client/rezervarile-mele')"
                >
                  {{ $t('generic.my_reservations') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <es-reservation-summary
            :date="getSelectedDate"
            :time="getSelectedTime"
            :is-reservation-confirmed="isReservationConfirmed"
            :elite-id="getReservationDetails.target_elite_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { ReservationSummary } from '@/components/shared/reservation-summary';

  export default Vue.extend({
    name: 'es-reserve-service-payment',

    components: {
      'es-reservation-summary': ReservationSummary,
    },

    data: () => ({
      isReservationConfirmed: true,
    }),

    computed: {
      ...mapGetters({
        getSelectedTime: 'services/getSelectedTime',
        getSelectedDate: 'services/getSelectedDate',
        getReservationDetails: 'services/getReservationDetails',
      }),
    },

    methods: {
      getToRoute() {
        const { id, type } = this.$router.currentRoute.params;
        return `/servicii/${type}/${id}/rezerva`;
      },
    },
  });
</script>
