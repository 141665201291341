import { render, staticRenderFns } from "./ReservationSummary.vue?vue&type=template&id=1f07adc4&scoped=true&"
import script from "./ReservationSummary.vue?vue&type=script&lang=js&"
export * from "./ReservationSummary.vue?vue&type=script&lang=js&"
import style0 from "./ReservationSummary.vue?vue&type=style&index=0&id=1f07adc4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f07adc4",
  null
  
)

export default component.exports